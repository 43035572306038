/*
Structure:
  .BaseTable
*/

.BaseTable {
  font-family: 'PwC Helvetica Neue', 'Helvetica Neue', sans-serif;
  font-size: var(--font-size-primary);

  @apply h-full w-full font-normal;

  --ag-foreground-color: rgb(20 20 20);
  --ag-header-foreground-color: rgb(20 20 20);
  --ag-header-background-color: rgb(242 242 242);
  --ag-row-hover-color: rgba(0, 0, 0, 0.03);
  --ag-background-color: #fff;
  --ag-odd-row-background-color: rgb(247 247 247);
  --ag-range-selection-border-color: transparent;
}
